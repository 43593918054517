




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Indicator, ViewItem } from '@/models'
import { IndicatorFragment } from './fragments'
import Loading from '@/components/Loading.vue'
import ComponentHeader from '@/components/ViewComponentHeader.vue'
import FilterForm from '@/components/form/FilterForm.vue'
import Result from './Result.vue'
import gql from 'graphql-tag'

interface FilterValue {
  filterId?: string
  filterOptions: Record<string, any>
}

@Component({
  components: {
    Loading,
    ComponentHeader,
    FilterForm,
    Result
  },
  apollo: {
    indicator: {
      query: gql`
        query indicator($indicatorId: ID) {
          indicator(indicatorId: $indicatorId) {
            ...Indicator
            indicatorType {
              _id
              requireCollection
            }
            filters {
              _id
              title
              schema: serializedSchema(includeParameters: true)
              formSchema: serializedSchema(includeParameters: false)
            }
          }
        }
        ${IndicatorFragment}
      `,
      variables() {
        return {
          indicatorId: this.componentId
        }
      }
    }
  }
})
export default class IndicatorView extends Vue {
  @Prop({ type: String }) environmentId!: string
  @Prop({ type: String }) componentId!: string
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Boolean, default: false }) editing!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) itemDefinition!: ViewItem

  indicator: Indicator | null = null
  filter: FilterValue | null = null

  @Watch('indicator')
  setDefaultFilter(indicator: Indicator) {
    if (indicator.filterByDefault) {
      this.filter = {
        filterId: indicator.filterByDefault,
        filterOptions: this.viewParams
      }
    } else if (indicator.allowsNoFilter) {
      this.filter = { filterId: undefined, filterOptions: this.viewParams }
    } else if (indicator.filters && indicator.filters.length >= 1) {
      this.filter = {
        filterId: indicator.filters[0]._id,
        filterOptions: this.viewParams
      }
    }
  }
}
