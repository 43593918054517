import gql from 'graphql-tag'

export const IndicatorUpdateFragment = gql`
  fragment IndicatorUpdate on Indicator {
    name
    title
    collectionId
    fieldName
    filtersIds
    filterByDefault
    allowsNoFilter
    indicatorTypeId
    link
    roles
    options
    orderFiltersByName
    disableRefresh
  }
`

export const IndicatorCreateFragment = gql`
  fragment IndicatorCreate on Indicator {
    _id
    environmentId
    createdAt
  }
`

export const IndicatorFragment = gql`
  fragment Indicator on Indicator {
    ...IndicatorCreate
    ...IndicatorUpdate
  }

  ${IndicatorCreateFragment}
  ${IndicatorUpdateFragment}
`
